<template>

  <div class="bg-container page">
    <img class="bg-img" src="img/login/login-bg.png">
    <div class="bg-wrapper">
      <div class="register">
        <van-nav-bar class="nav-bar">
          <template #left>
            <van-icon name="arrow-left" color="#fff" @click="back()"/>
          </template>
        </van-nav-bar>
        <div class="wrapper">
          <div class="title" style="font-size: 12vw; margin-top: 30px; color:#ff968f">WISE</div>
          <div class="loginFormy">
          <div @click="toLogin()" class ="loginformt">
          <span>{{ $t("登录") }}</span>
          </div>	
          <div class ="loginformu">
          <span>{{ $t("注册") }}</span>	
          </div>	
          </div>
          <div class="loginForm">
			 <div class="loginForlogo">
			 	TẠO TÀI KHOẢN MỚI
			 	</div> 
			  
            <van-field v-model="username" class="input"  :placeholder="$t('请输入用户名(6-12个英文字母或数字)')" />
            <van-field
                v-model="password"
                :type="passwordType"
                class="input"
                :placeholder="$t('请输入登录密码(6-12个英文字母或数字)')">
              <template slot="right-icon">
                <van-icon :name=" passwordType === 'password' ? 'closed-eye':'eye-o'" @click="switchPasswordType"/>
              </template>
            </van-field>
            <van-field v-model="code"  class="input"  :placeholder="$t('请输入邀请码')" />
           <!-- <div class="agreement">
              <van-checkbox v-model="checked" />
              <span class="agreement-text">{{ $t("我已经知晓并同意'开户协议'各项条约") }}</span>
            </div> -->
            <van-button class="login-btn"   type="primary" size="normal" @click="doRegister()">{{ $t("注册") }}</van-button>
         
		 <div @click="toLogin()" class="register-text">
		   <span>{{ $t("已有账号去登入")}}</span>
		    <div class="zc">{{ $t("登录") }}
		   
		   </div>
		 </div>
		  </div>
		  
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'inputValue',
    event: 'input'
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checked: true,
      username: '',
      code: '',
      password: this.inputValue,
      passwordType: 'password',
    };
  },
  methods: {
    switchPasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    back(){
      return window.history.back();
    },
	toLogin(){
	  this.$router.push("Login")
	},
    doRegister() {
      if(this.username === "" || this.username === null || this.username === undefined){
        this.$toast(this.$t("请输入用户名"));
        return false;
      }
      if(this.password === "" || this.password === null || this.password === undefined){
        this.$toast(this.$t("请输入密码"));
        return false;
      }
      if(this.code === "" || this.code === null || this.code === undefined){
        this.$toast(this.$t("请输入邀请码"));
        return false;
      }
      if(!this.checked){
        this.$toast(this.$t("请勾选下方开户协议！"));
        return false;
      }
      this.$http({
        method: 'post',
        data:{
          username:this.username,
          password:this.password,
          code:this.code
        },
        url: 'member_register'
      }).then(res=>{
          if(res.code === 200){
            this.$toast.success(res.msg);
            localStorage.setItem('token',res.data)
            this.$router.push("Mine")
          }else {
            this.$toast.fail(res.msg);
          }
      })
    }
  },
  created() {
    if(localStorage.getItem('token')){
      return window.history.back();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.register{
  height: 100%;
}
.bg-container .bg-wrapper .register .nav-bar{
  background: 0 0
}
.register .wrapper {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.logo-container{
  margin: 0 auto;
  width: 45%;
}
.logo-container .logo-wrapper{
  position: relative;
  padding-bottom: 62.5%;
}
.register .wrapper .loginForm{
  padding: 20px;
  margin: 20px 20px;
  border-radius: 30px;
  background: #fff;
  }


.register .wrapper .loginForm .loginForlogo{
  padding: 10px;
  margin: 20px 20px;
  border-radius: 30px;
  text-align: center;
  color: rgb(255, 150, 143);
  font-size: 1.3rem;
}

.logo-container .logo-wrapper .logo-img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.register .wrapper .loginFormy{
     width: 21rem;
	 height: 4rem;
     justify-content: center;
     margin: 0 auto 3rem;
	 font-size: 1.3rem;
     border: none;
	 margin-top: 20px;
     border-radius: 3rem;
     background-color: #ff968f;
  justify-content: center;
  display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.register .wrapper .loginFormy .loginformt{
     width: 10rem;
	 height: 3rem;
	 margin-top: 0.5rem;
     justify-content: center;
     border-radius: 3rem;
     color: #fff;
display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
	  list-style: none;
align-items: center;
}

.register .wrapper .loginFormy .loginformu{
     width: 10rem;
	 height: 3rem;
	 margin-top: 0.5rem;
     justify-content: center;
     border-radius: 3rem;
	 background-color: #ffffff;
	 color: #000;

display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
	  list-style: none;
align-items: center;
}

.register .wrapper .title{
  line-height: 100px;
  text-align: center;
  font-size: 45px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 5px;
}
.register .wrapper .loginForm{
  padding: 60px;
}
.register .wrapper .loginForm .input{
 padding: 10px 20px;
 margin-top: 40px;
 border-radius: 20px;
 
 line-height: 80px;
 font-size: 30px;
 color: #4e4e4e;
 border: 1px solid #a8a8a8;
}
::v-deep .van-field__right-icon .van-icon {
  font-size: 50px;
}
::v-deep .van-icon {
  font-size: 50px;
}
.register .wrapper .loginForm .reset-text{
  margin: 30px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.register .wrapper .loginForm .reset-text span{
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: 15px;
}
.register .wrapper .loginForm .register-text{
padding-top: 40px;
  
  display: flex;
  align-items: center;
  justify-content: center;
}
.register .wrapper .loginForm .register-text span{
  color: #000;
  font-size: 0.8687rem;
  font-weight: 500;
  line-height: 20px;
}
.register .wrapper .loginForm .register-text .zc{
	color: #ff5500;
}
.register .wrapper .loginForm .login-btn{
  margin-top: 30px;
  width: 50%;
  height: 100px;
  border-radius: 50px;
  color: #fff;
  margin-left: 25%;
  background-color: #ff5500;
  font-size: 30px;
  font-weight: bolder;
  border: none;
}
.register .wrapper .loginForm .agreement{
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register .wrapper .loginForm .agreement .agreement-text{
  margin-left: 10px;
  font-size: 25px;
  color: #fff;
  font-weight: 500;
  line-height: 30px;
}
::v-deep .agreement .van-icon {
  font-size: 30px;
}
::v-deep .agreement .van-checkbox__icon {
  font-size: 38px;
}
::v-deep .agreement .van-checkbox__icon--checked .van-icon {
  color: #fff;
  border-color: rgb(126, 86, 120);
  background-color: rgb(126, 86, 120);
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
//首页
import Home from '../pages/home/index.vue'/* 首页 */
import Mine from '../pages/mine/index.vue'/* 我的 */
import Choose from '../pages/choose/index.vue'/* 选妃 */
import List from '../pages/choose/list.vue'/* 选妃列表 */
import Profile from '../pages/choose/profile.vue'/* 选妃详情 */
import Video from '../pages/video/index.vue'/* 视频 */
import Game from '../pages/game/index.vue'/* 游戏 */
import Login from '../pages/login/index.vue'/* 登录 */
import Register from '../pages/login/register.vue'/* 注册 */
import Language from '../pages/login/language.vue'/* yuyan */
import ServiceOnline from '../pages/mine/ServiceOnline.vue'/* 客服列表 */
import ServicePage from '../pages/mine/ServicePage.vue'/* 客服详情界面 */
import Setting from '../pages/mine/Setting.vue'/* 设置 */
import Infomation from '../pages/mine/Infomation.vue'/* 基本信息 */
import Setname from '../pages/mine/Setname.vue'/* 修改姓名 */
import Setsex from '../pages/mine/Setsex.vue'/* 修改姓名 */
import SetPayPassword from '../pages/mine/SetPayPassword.vue'/* 修改提现密码 */
import SetLoginPassword from '../pages/mine/SetLoginPassword.vue'/* 修改提现密码 */
import Lottery from '../pages/lottery/index.vue'/* 彩票详情 */
import Notice from '../pages/mine/Notice.vue'/* 公告 */
import PlayVideo  from '../pages/video/PlayVideo'/* 视频播放页面 */
import Setbank  from '../pages/mine/Setbank'/* 视频播放页面 */
import BindCard  from '../pages/mine/BindCard'/* 绑定银行卡界面 */
import Withdraw  from '../pages/mine/Withdraw'/* 绑定银行卡界面 */
import Recharge  from '../pages/mine/Recharge'/* 充值 */
import Personalreport  from '../pages/mine/Personalreport'/* 个人表报 */
import GameRecord  from '../pages/mine/GameRecord'/* 游戏记录 */
import Moneylog  from '../pages/mine/Moneylog'/* 账户明细 */
import WithdrawRecord  from '../pages/mine/WithdrawRecord'/* 提现记录 */
import RechargeRecord  from '../pages/mine/RechargeRecord'/* 充值记录 */

import Detail1 from '../pages/detail/detail1.vue'
import Detail2 from '../pages/detail/detail2.vue'
import Detail3 from '../pages/detail/detail3.vue'
import Detail4 from '../pages/detail/detail4.vue'
import Detail5 from '../pages/detail/detail5.vue'
import Detail6 from '../pages/detail/detail6.vue'
import Detail7 from '../pages/detail/detail7.vue'
import Detail8 from '../pages/detail/detail8.vue'
import Detail9 from '../pages/detail/detail9.vue'
import Detail10 from '../pages/detail/detail10.vue'
import Detail11 from '../pages/detail/detail11.vue'
import Detail12 from '../pages/detail/detail12.vue'






Vue.use(VueRouter)
const routes = [
    {path:'/',redirect:'/Home',component:Home,meta:{title:''}},
    {path:'/Home',name:'home',component:Home,meta:{title:''}},
	{path:'/Choose',name:'choose',component:Choose,meta:{title:''}},
	{path:'/List',name:'list',component:List,meta:{title:''}},
	{path:'/Profile',name:'profile',component:Profile,meta:{title:''}},
    {path:'/Mine',name:'mine',component:Mine,meta:{title:''}},
    {path:'/Video',name:'video',component:Video,meta:{title:''}},
    {path:'/Game',name:'game',component:Game,meta:{title:''}},
	{path:'/VOTE',name:'game',component:Game,meta:{title:''}},
    {path:'/Login',name:'login',component:Login,meta:{title:''}},
    {path:'/Register',name:'register',component:Register,meta:{title:''}},
	{path:'/Language',name:'language',component:Language,meta:{title:''}},
    {path:'/ServiceOnline',name:'ServiceOnline',component:ServiceOnline,meta:{title:''}},
    {path:'/ServicePage',name:'ServicePage',component:ServicePage,meta:{title:''}},
    {path:'/Setting',name:'Setting',component:Setting,meta:{title:''}},
    {path:'/Infomation',name:'Infomation',component:Infomation,meta:{title:''}},
    {path:'/Setname',name:'Setname',component:Setname,meta:{title:''}},
    {path:'/Setsex',name:'Setsex',component:Setsex,meta:{title:''}},
    {path:'/SetPayPassword',name:'SetPayPassword',component:SetPayPassword,meta:{title:''}},
    {path:'/SetLoginPassword',name:'SetLoginPassword',component:SetLoginPassword,meta:{title:''}},
    {path:'/Lottery',name:'Lottery',component:Lottery,meta:{title:''}},
    {path:'/Notice',name:'Notice',component:Notice,meta:{title:''}},
    {path:'/PlayVideo',name:'PlayVideo',component:PlayVideo,meta:{title:''}},
    {path:'/Setbank',name:'Setbank',component:Setbank,meta:{title:''}},
    {path:'/BindCard',name:'BindCard',component:BindCard,meta:{title:''}},
    {path:'/Withdraw',name:'Withdraw',component:Withdraw,meta:{title:''}},
	{path:'/Recharge',name:'Recharge',component:Recharge,meta:{title:''}},
    {path:'/Personalreport',name:'Personalreport',component:Personalreport,meta:{title:''}},
    {path:'/WithdrawRecord',name:'WithdrawRecord',component:WithdrawRecord,meta:{title:''}},
	{path:'/RechargeRecord',name:'RechargeRecord',component:RechargeRecord,meta:{title:''}},
    {path:'/GameRecord',name:'GameRecord',component:GameRecord,meta:{title:''}},
	{path:'/Moneylog',name:'Moneylog',component:Moneylog,meta:{title:''}},
	{path:'/Detail1',name:'Detail1',component:Detail1,meta:{title:''}},
	{path:'/Detail2',name:'Detail2',component:Detail2,meta:{title:''}},
	{path:'/Detail3',name:'Detail3',component:Detail3,meta:{title:''}},
	{path:'/Detail4',name:'Detail4',component:Detail4,meta:{title:''}},
	{path:'/Detail5',name:'Detail5',component:Detail5,meta:{title:''}},
	{path:'/Detail6',name:'Detail6',component:Detail6,meta:{title:''}},
	{path:'/Detail7',name:'Detail7',component:Detail7,meta:{title:''}},
	{path:'/Detail8',name:'Detail8',component:Detail8,meta:{title:''}},
	{path:'/Detail9',name:'Detail9',component:Detail9,meta:{title:''}},
	{path:'/Detail10',name:'Detail10',component:Detail10,meta:{title:''}},
	{path:'/Detail11',name:'Detail11',component:Detail11,meta:{title:''}},
	{path:'/Detail12',name:'Detail12',component:Detail12,meta:{title:''}},

];

//生成路由实例
const router = new VueRouter({
    routes
})
router.beforeEach((to,from,next) => {         //修改标题路由配置加上这个
    document.title = to.matched[0].meta.title
    next()
})

export default router
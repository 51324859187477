<template>
  <div class="container page">
    <div class="header">
      <van-nav-bar :title="$t('充值')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
		<template #right>
		  <span class="nav-right" @click="$router.push({path:'/RechargeRecord'})">{{ $t("充值记录") }}</span>
		</template>
      </van-nav-bar>
      <div class="info">
        <p class="title">{{ $t("当前余额") }}</p>
        <p class="value">{{this.userInfo.money}}</p>
       
      </div>
      <div class="content recharge">
        <!-- <van-form> -->
			
			<div  class="form-item">
				<div  class="form-item-title">{{ $t("请输入充值金额") }}</div>
				<div >
					<van-field v-model="recharge_money" type="number" :placeholder="$t('请输入充值金额')" style="font-size: 15px;"></van-field>
					
					<van-field v-model="bank" :label="$t('类型')" readonly :placeholder="$t('请选择充值类型')" @click="showSelectBanks()"  style="font-size: 15PX;"/>
					
				</div>
			</div>
			
			<div  class="form-item">
				<div style="margin: 16px;">
					<van-button class="van-button--info van-button--block van-button--round " type="default" @click="doRecharge()">{{ $t("充值") }}</van-button>
				</div>
			</div>
			
		<!-- </van-form> -->
      </div>
    </div>
	
	<van-popup v-model="showBank" round position="bottom" :style="{ height: '35%' }" >
	  <van-picker
	      show-toolbar
	      :columns="banks"
	      @confirm="onConfirm"
	      @cancel="onCancel"
		  :confirm-button-text="$t('确认')"
		  :cancel-button-text="$t('取消')"
	  />
	</van-popup>
	
  </div>
</template>

<script>
export default {
  data() {
    return {
		 banks: [],
		userInfo:{
		},
      win_money:0,
	  recharge_money:"",
	  bankcode:'',
	  bank:'',
	  showBank:false,
      personalreport:{}
    };
  },
  methods: {
	  doRecharge(){
		  // alert(11);
		  if(this.bankcode === "" || this.bankcode === null || this.bankcode === undefined){
		    this.$toast.fail(this.$t('请选择充值类型'));
		    return false;
		  }
		  if(this.recharge_money <= 0){
		    this.$toast(this.$t('请填写正确的金额'));
		    return false;
		  }else {
		    this.$http({
		      method: 'post',
		      data:{money:this.recharge_money,bankcode:this.bankcode},
		      url: 'user_set_recharge'
		    }).then(res=>{
		      if(res.code === 200){
		        location.href = res.data;
		      }else if(res.code ===401){
		        this.$toast(res.msg);
		      }
		    })
		  }
	  },
	  onRefresh() {
	    setTimeout(() => {
	      if(localStorage.getItem('token')){
	          this.getUserInfo();
	      }
	    }, 500);
	  },
	  getUserInfo(){
	    this.$http({
	      method: 'get',
	      url: 'user_info'
	    }).then(res=>{
	      if(res.code === 200){
	          this.userInfo = res.data;
	          if(this.userInfo.status !== 1){
	            this.$toast(this.$t('账号下线'));
	            localStorage.clear()
	            this.$router.push({path:'/Login'})
	          }
	      }else if(res.code ===401){
	        this.$toast(res.msg);
	      }
	    })
	  },
    back(){
      return window.history.back();
    },
	showSelectBanks(){
	  this.showBank = true;
	},
	getBankList(){
	  this.$http({
	    method: 'get',
	    url: 'sys_get_banksrg'
	  }).then(res=>{
	    if(res.code === 200){
	      this.banks = res.data;
	    }else if(res.code ===401){
	      this.$toast(res.msg);
	    }
	  })
	},
	onConfirm(value) {
		this.bank = value.text
	  this.bankcode = value.value
	  this.showBank = false;
	},
	onCancel() {
	  this.showBank = false;
	},
    getPersonalreport(){
      this.$http({
        method: 'get',
        url: 'user_get_personalreport'
      }).then(res=>{
        if(res.code === 200){
          this.personalreport = res.data;
          this.win_money = this.personalreport.win_money - this.personalreport.play_money;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
		this.getUserInfo();
		this.getBankList();
      this.getPersonalreport();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-ellipsis{
	font-size: 32px !important;
}
.container .header{
  background: linear-gradient(270deg,#9e9ce7,#500cfd);
}


.van-button {
    height: 11.6vw!important;
}
.form-item {
    margin-top: 5.333vw;
}

.form-item-title {
    font-size: 4.8vw;
    font-weight: 700;
    color: #999;
    margin-bottom: 2.667vw;
}

.van-cell {
    line-height: 8.667vw!important;
}

.recharge {
    padding: 1.333vw 4vw;
}

.recharge span {
    font-size: 4vw;
    color: #fff;
    font-weight: 500;
}

.container .header .info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  padding-top: 10px;
  margin: auto;
}
.container .header .info .title{
  font-size: 25px;
  color: #e5e5e5;
}
.container .header .info .value{
  margin: 10px auto;
  color: #fff;
  font-size: 50px;
  border-bottom: 1px solid #fff;
}
.container .header .info .tip{
  font-size: 30px;
  color: #e5e5e5;
}
.container .content{
  flex: 1;
  background: #f2f2f5;
}
.container .content .datalist{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}
.container .content .datalist .datalistitem{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 33.3%;
}
.container .content .datalist .datalistitem .datalistitemValue{
  color: #ff253f;
  font-size: 35px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.container .content .datalist .datalistitem .datalistitemKey{
  color: #979799;
  font-size: 25px;
  margin-bottom: 10px;
  margin-top: 10px;
}




</style>
